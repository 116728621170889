<template>
    <div id="ekkFilter" class="func-box">
        <div class="export-box" ref="exportBtn" v-if="!noExport">
            <span class="export" @click="exportData(keyword, start_time, end_time, status, month)">导出</span>
        </div>
        <!--<el-button
            v-if="!noExport"
            size="mini"
            class="export l"
            @click="exportData(keyword, start_time, end_time, status, month)"
        >
            导出
        </el-button>-->
        <el-button
            v-if="otherBtn.show"
            size="mini" class="solid-btn"
            @click="handleBtn"
        >
            {{otherBtn.text}}
        </el-button>
        <el-button
            v-if="otherBtn2.show"
            size="mini" class="solid-btn"
            @click="handleBtn2"
        >
            {{otherBtn2.text}}
        </el-button>
    
        <el-date-picker
            v-if="!noDate"
            v-model="timeValue"
            type="daterange"
            class="big-picker"
            size="mini"
            start-placeholder="开始日期"
            range-separator="至"
            end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-date-picker
            v-if="haveMonth"
            class="my-month r"
            v-model="monthValue"
            type="month"
            format="yyyy 年 MM 月"
            value-format="yyyy-MM"
            placeholder="选择月份"
        >
        </el-date-picker>
        <el-input
            v-if="!noSearch"
            size="mini" class="small-search"
            v-model="keyword"
            prefix-icon="el-icon-search"
            :placeholder="'搜索' + placeholder"
        >
        </el-input>

        <el-select
            v-if="!noStatus"
            size="mini" class="small-option"
            v-model="status"
        >
            <el-option
                v-for="item in statusList"
                :key="item.id" :value="item.id"
                :label="item.name"
            >
            </el-option>
        </el-select>
        <el-select
            v-if="!noStatus2"
            size="mini" class="small-option"
            v-model="status2"
        >
            <el-option
                v-for="item in statusList2"
                :key="item.id" :value="item.id"
                :label="item.name"
            >
            </el-option>
        </el-select>
    </div>
</template>

<script>
import util from "@/utils/util";
export default {
    name: "ekk-filter",

    props: {
        // 筛选列表
        statusList: {
            type: Array,
            default: () => {
                return []
            }
        },
        statusList2: {
            type: Array,
            default: () => {
                return []
            }
        },

        // 其他按钮
        otherBtn: {
            type: Object,
            default: () => {
                return {
                    show: false,
                    text: ''
                }
            }
        },
        otherBtn2: {
            type: Object,
            default: () => {
                return {
                    show: false,
                    text: ''
                }
            }
        },

        // 搜索框提示
        placeholder: {
            type: String,
            default: ''
        },

        // 导出隐藏
        noExport: {
            type: Boolean,
            default: false
        },

        // 搜索隐藏
        noSearch: {
            type: Boolean,
            default: false
        },

        // 日期选择框隐藏
        noDate: {
            type: Boolean,
            default: false
        },

        // 状态筛选隐藏
        noStatus: {
            type: Boolean,
            default: false
        },
        noStatus2: {
            type: Boolean,
            default: true
        },
        status: {
            default: ''
        },
        status2: {
            default: ''
        },

        // 清空搜索框
        emptyKeyword: {
            type: Boolean,
            default: false
        },

        // 是否显示月份选择框
        haveMonth: {
            type: Boolean,
            default: false
        },

        // 清空月份选择框
        emptyMonth: {
            type: Boolean,
            default: false
        }
    },

    data(){
        return{
            keyword: '',
            timeValue: null,
            monthValue: null,
            month: '',
            // status: '',
            // status2: '',
            start_time: '',
            end_time: ''
        }
    },

    mounted(){
        this.$watch('keyword', util.debounce(val => {
            this.$emit('keyword', val)
        }, 500))
    },

    watch: {
        // 日期选择
        timeValue(val){
            const that = this;
            if(val){
                that.start_time = val[0].getTime() / 1000;
                that.end_time = val[1].getTime() / 1000 + 86399
            }else{
                that.start_time = '';
                that.end_time = ''
            }
            that.$emit('timeValue', that.start_time, that.end_time)
        },

        // 状态筛选
        status(val){
            this.$emit('status', val)
        },
        status2(val){
            this.$emit('status2', val)
        },

        emptyKeyword(val){
            if(val) this.keyword = ''
        },

        // 月份选择
        monthValue(val){
            let that = this, month = '';
            if(val){
                month = new Date(`${val}-01`);
                month = month.getTime() / 1000
            }
            that.month = month;
            that.$emit('getMonth', month)
        },

        emptyMonth(val){
            if(val) this.monthValue = null
        }

    },

    methods: {
        // 导出数据
        exportData(keyword, start_time, end_time, status, month){
            this.$emit('exportData', keyword, start_time, end_time, status, month)
        },

        handleBtn(){
            this.$emit('handleBtn')
        },
        handleBtn2(){
            this.$emit('handleBtn2')
        }
    }
}
</script>